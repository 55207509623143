/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;


/* TODO: Find theme */

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-legacy-component-typographies();
@include mat.legacy-core();
$my-app-primary: mat.define-palette(mat.$grey-palette, 700, 600, 500);
$my-app-accent: mat.define-palette(mat.$pink-palette, 500, 900, A100);
$my-app-warn: mat.define-palette(mat.$deep-orange-palette);
$my-app-theme: mat.define-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
@include mat.all-legacy-component-themes($my-app-theme);
@font-face {
    font-family: "Brusher";
    src: local("Brusher"), url(/assets/fonts/Brusher.ttf) format("truetype");
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

.app-name {
    font-family: "Brusher", Arial, Helvetica, sans-serif;
    font-size: 48px;
    color: #454545;
}

.app-line {
    margin: 20px;
    border-bottom: 1px solid;
    border-color: #454545;
    width: 250px;
}

.app-description {
    font-size: 20px;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    color: #454545;
}